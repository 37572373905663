<script setup lang="ts">
    export interface BaseRendererCmsSequence {
        noGrid?: boolean;
        isContentPage?: boolean;
    }
    interface RendererCmsSequence extends BaseRendererCmsSequence {
        cmsComponent: SequenceElement;
        index: number;
    }

    defineProps<RendererCmsSequence>();

    const isTextElement = (item: SequenceElement): item is SequenceElementText => item.type === 'text';
</script>
<template>
    <cms-text-block
        v-if="isTextElement(cmsComponent)"
        :key="`text-${index}`"
        :markup="cmsComponent.data"
        :no-grid="noGrid" />
    <component
        v-else
        :is="cmsComponent.type"
        :key="cmsComponent.data.codename"
        :data="cmsComponent.data"
        :no-grid="noGrid"
        :component-index="isContentPage ? index : undefined" />
</template>
