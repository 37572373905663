<script setup lang="ts">
    import type { BaseRendererCmsSequence } from '~/components/renderer/CmsComponent.vue';

    const REG_COMP_OR_ITEM = /<object type="application\/kenticocloud"([^>]*)><\/object>/gi;
    /**
     * renders components and mixed content and components
     */
    interface ContentControllerProps extends BaseRendererCmsSequence {
        data: OneOf<[WidgetContent<any>, WidgetList<any>]>;
    }

    const props = defineProps<ContentControllerProps>();

    const emit = defineEmits<{
        (e: 'componentSequence', data: SequenceElement[]): void;
    }>();

    const appStore = useAppStore();

    /**
     * generates the sequence of widgets and markup
     */
    const componentSequence: SequenceElement[] = [];
    const data = props.data;

    const resolveByComponentId = (componentId: string) => {
        const ref = componentId.startsWith('inline:')
            ? data.components?.find((p: any) => p.id === componentId) // UNKNOWN_TYPE
            : appStore.getReference(componentId);
        if (!Array.isArray(ref?.types)) {
            return console.error(`warning: no config data found for element with id ${componentId}`);
        }

        if (!shouldDisplayWidget(ref)) return;

        const findComponentFromTypesArray = () => {
            for (let i = ref.types.length - 1; i >= 0; i -= 1) {
                const type = ref.types[i];
                const component = resolveWidget(type);
                if (component) return component;
            }
        };

        const component = ref.types.includes('WidgetPlaceholder') ? resolveWidget(ref.type) : findComponentFromTypesArray();
        if (!component) {
            return console.error(`component not found: ${ref.types}`);
        }

        // console.log('got component', comp);
        componentSequence.push({
            type: component,
            data: ref,
        });
    };

    if (data.value) {
        // console.log('data value', data.value);
        // split the values by object, inject the content if just text
        // console.log('content split', this.data.value.split(REG_COMP_OR_ITEM))
        const splitContent = data.value.split(REG_COMP_OR_ITEM);
        for (const content of splitContent) {
            if (!content) continue;
            if (content.startsWith(' data-type=')) {
                // parse component
                const componentData = content.match(/data-rel="([^"]+)".*data-id="([^"]+)"/i);
                const componentId = componentData?.[2];
                if (!componentId) {
                    console.error(`warning: couldn't parse object declaration in content: ${componentData}`);
                    continue;
                }
                resolveByComponentId(componentId);
            } else if (content.trim() !== '' && content.trim() !== '<p><br></p>') {
                componentSequence.push({
                    type: 'text',
                    data: textLinks(content, appStore.getReference),
                });
            }
        }
    } else if (data.sequence) {
        for (const componentId of data.sequence) {
            resolveByComponentId(componentId);
        }
    }

    emit('componentSequence', componentSequence);
</script>
<template>
    <div :class="['cms-content-controller w-full', { 'no-grid': noGrid }]">
        <template v-for="(component, index) in componentSequence">
            <renderer-cms-component
                :cms-component="component"
                :index="index"
                :no-grid="noGrid"
                :is-content-page="isContentPage" />
        </template>
    </div>
</template>
